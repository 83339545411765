.textalign {
    text-align: center;
    color: #999999;
    font-size: 13px;
    line-height: 1.5em;
    font-weight: 500;
}

.forgetText {
    font-size: 14px;
    padding-left: 3px;
    color: rgb(27, 105, 156);
}

.link {
    color: #4F8BB2;
}

// .solverse-logo {
//     position: fixed;
//     top: 4%;
//     left: 4%;
// }