.ClippedDrawer-content-13 {
    padding: 12px !important;
}

.MuiIconButton-root-917 {
    padding: none;
}

.content {
    padding: 20px;
}